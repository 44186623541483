import {
  ArrowLeftOutlined,
  CloseCircleOutlined,
  FilePdfOutlined,
  MinusCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Upload,
} from 'antd';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { mongoClient } from '../../apollo';
import {
  CATEGORIES_KEYS,
  COURT_TYPES,
  LIMIT,
  defaultDateFormat,
} from '../../common/constants';
import { beforeUploadDocFile, formValidatorRules } from '../../common/utils';
import MongoCommonSelect from '../../components/MongoCommonSelect';
import { IMPORT_JUDGEMENT } from './graphql/mutations';
import {
  GET_BENCH_LIST,
  GET_JUDGEMENT_SIGNED_URL,
  VALIDATE_PDF_URL,
} from './graphql/queries';

const { required } = formValidatorRules;

const defaultFormValue = {
  appealNumber: '',
  appellant: '',
  court: null,
  dateOfOrder: '',
  pdfUrl: '',
  respondent: '',
  isPdfUrl: true,
};

const ImportJudgement = () => {
  const navigate = useNavigate();
  const { category } = useParams();
  const [form] = Form.useForm();

  const [isFieldsChanged, setIsFieldsChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [warning, setWarning] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [validationStatus, setValidationStatus] = useState({});
  const [currentIndex, setCurrentIndex] = useState(null);
  const [isPDFUrlValidate, setIsPDFUrlValidate] = useState(true);

  const importJudgementMutate = async ({ variables }) => {
    setIsLoading(true);
    await mongoClient
      ?.mutate({
        mutation: IMPORT_JUDGEMENT,
        variables,
      })
      ?.then(() => {
        setIsLoading(false);
        navigate(-1);
      })
      ?.catch(() => {
        setIsLoading(false);
      });
  };

  const getSignedUrl = async ({ variables }) => {
    try {
      const res = await mongoClient.query({
        query: GET_JUDGEMENT_SIGNED_URL,
        fetchPolicy: 'network-only',
        variables: { ...variables },
      });
      return res;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching signed URL', error);
    }
  };

  const validateUrlCall = async () => {
    try {
      const res = await mongoClient.query({
        query: VALIDATE_PDF_URL,
        fetchPolicy: 'network-only',
        variables: {
          data: {
            url: pdfUrl,
          },
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  };

  const uploadFile = async (url, file) => {
    try {
      await fetch(`${url}`, {
        method: 'PUT',
        body: file,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error upload file', error);
    }
  };

  const onFinish = async (values) => {
    const urls = await Promise.all(
      fileList?.map(async (item) => {
        try {
          const response = await getSignedUrl({
            variables: {
              data: {
                fileName: item?.file?.name,
              },
            },
          });
          return {
            name: item?.key,
            file: item?.file,
            url: response?.data?.getPdfFileUploadSignedUrl?.signedUrl,
            key: response?.data?.getPdfFileUploadSignedUrl?.key,
          };
        } catch (error) {
          return null;
        }
      }),
    );

    await Promise.all(
      urls?.map(async (item) => {
        try {
          return uploadFile(item?.url, item?.file);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error fetching signed URL:', error);
        }
      }),
    );

    const preparedData = values?.judgement?.map((item, i) => ({
      appealNumber: item?.appealNumber,
      appellant: item?.appellant,
      category: CATEGORIES_KEYS[category],
      court: item?.court,
      dateOfOrder: item?.dateOfOrder
        ? item?.dateOfOrder?.toISOString()
        : item?.dateOfOrder,
      scrapePdfUrl: !item?.isPdfUrl
        ? urls?.find((ele) => ele?.name === i)?.key
        : null,
      pdfUrl: item?.isPdfUrl ? item?.pdfUrl : null,
      respondent: item?.respondent,
    }));

    importJudgementMutate({
      variables: {
        data: preparedData,
      },
    });
  };

  const handleFileChange = async (info, name) => {
    const fileObj = info?.file?.originFileObj;
    const preparedData = fileList?.map((item) =>
      item?.key === name ? { key: name, file: fileObj } : item,
    );

    if (!preparedData?.some((item) => item?.key === name)) {
      preparedData?.push({ key: name, file: fileObj });
    }
    setIsPDFUrlValidate(true);
    setFileList(preparedData);
  };

  const handleRemoveFile = (name) => {
    const updatedFileList = fileList?.filter((item) => item?.key !== name);
    setFileList(updatedFileList);
  };

  useEffect(() => {
    const validateUrl = async (name) => {
      const { data } = await validateUrlCall();
      const isValid = data?.validateContentUrl?.isValid;
      const code = data?.validateContentUrl?.code;
      if (isValid) {
        setValidationStatus((prev) => ({ ...prev, [name]: 'success' }));
        setIsPDFUrlValidate(true);
        return Promise.resolve();
      }
      if (!isValid && code === 'INVALID') {
        setValidationStatus((prev) => ({ ...prev, [name]: 'error' }));
        setIsPDFUrlValidate(false);
        return Promise.reject(new Error('Please enter a valid Pdf Url'));
      }
      if (!isValid && code === 'TIMEOUT') {
        setWarning(true);
        setIsPDFUrlValidate(true);
        setValidationStatus((prev) => ({ ...prev, [name]: 'warning' }));
        return Promise.resolve();
      }
    };
    if (pdfUrl) {
      validateUrl(currentIndex);
    }
  }, [pdfUrl]);

  const handlePdfUrlChange = debounce((value, name) => {
    setIsPDFUrlValidate(false);
    setWarning(false);
    setCurrentIndex(name);
    setPdfUrl(value);
    if (!value) return;
    setValidationStatus((prev) => ({ ...prev, [name]: 'validating' }));
  }, 1000);

  return (
    <>
      <div className="title">
        <Button
          type="text"
          shape="circle"
          onClick={() => navigate(-1)}
          icon={<ArrowLeftOutlined />}
        />
        Import Judgement
      </div>
      <Card className="ant-body-scroll">
        <div className="card-body-wrapper">
          <Form
            form={form}
            name="import-judgement"
            onFinish={onFinish}
            initialValues={{ judgement: [defaultFormValue] }}
            onValuesChange={() => setIsFieldsChanged(true)}
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 20 }}
            layout="vertical"
          >
            <Form.List name="judgement">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Card
                      key={key}
                      title="Judgement"
                      extra={
                        fields?.length > 1 ? (
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        ) : null
                      }
                      className="mb-16"
                    >
                      <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, 'appealNumber']}
                            label="Appeal Number"
                            rules={[
                              {
                                required,
                                message: 'Please enter Appeal Number',
                              },
                            ]}
                          >
                            <Input placeholder="Enter Appeal Number" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, 'appellant']}
                            label="Appellant"
                            rules={[
                              { required, message: 'Please enter Appellant' },
                            ]}
                          >
                            <Input placeholder="Enter Appellant" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, 'court']}
                            label="Court"
                          >
                            <Select
                              placeholder="Select Court"
                              options={COURT_TYPES}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, 'bench']}
                            label="Bench"
                          >
                            <MongoCommonSelect
                              getPopupContainer={(trigger) =>
                                trigger?.parentNode
                              }
                              placeholder="Select Benches"
                              className="role-select-in-calls"
                              showSearch
                              allowClear
                              query={GET_BENCH_LIST}
                              fetchPolicy="network-only"
                              responsePath="data.benches"
                              valuePath="id"
                              labelPath="value"
                              variables={{
                                filter: {
                                  skip: 0,
                                  limit: LIMIT,
                                  search: '',
                                },
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, 'respondent']}
                            label="Respondent"
                            rules={[
                              {
                                required,
                                message: 'Please enter Respondent',
                              },
                            ]}
                          >
                            <Input placeholder="Enter Respondent" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} span={12}>
                          <Form.Item
                            label="Do you have pdf url?"
                            valuePropName="checked"
                            name={[name, 'isPdfUrl']}
                          >
                            <Switch />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, 'dateOfOrder']}
                            label="Date Of Order"
                          >
                            <DatePicker format={defaultDateFormat} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} span={12}>
                          <Form.Item
                            className="mb-0"
                            shouldUpdate={(prevValues, currentValues) => {
                              const prevIsPdfUrl =
                                prevValues?.judgement?.[name]?.isPdfUrl;
                              const currIsPdfUrl =
                                currentValues?.judgement?.[name]?.isPdfUrl;

                              if (
                                prevIsPdfUrl !== currIsPdfUrl &&
                                currIsPdfUrl
                              ) {
                                handleRemoveFile(name);
                              }

                              return prevIsPdfUrl !== currIsPdfUrl;
                            }}
                          >
                            {() =>
                              form.getFieldValue([
                                'judgement',
                                name,
                                'isPdfUrl',
                              ]) ? (
                                <>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'pdfUrl']}
                                    label="Pdf Url"
                                    validateStatus={
                                      validationStatus[name] ?? ''
                                    }
                                    hasFeedback
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please enter Pdf Url',
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Enter Pdf Url"
                                      onChange={debounce((e) => {
                                        setValidationStatus((prev) => ({
                                          ...prev,
                                          [name]: null,
                                        }));
                                        handlePdfUrlChange(
                                          e.target.value,
                                          name,
                                        );
                                      }, 1000)}
                                    />
                                  </Form.Item>
                                  {validationStatus[name] === 'warning' &&
                                    warning && (
                                      <Alert
                                        message="Unable to verify Pdf Url at the moment, Proceed anyway?"
                                        type="warning"
                                      />
                                    )}
                                </>
                              ) : (
                                <Form.Item
                                  name={[name, 'scrapePdfUrl']}
                                  label="Upload Pdf"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please Upload Pdf',
                                    },
                                  ]}
                                >
                                  {fileList?.[name]?.file ? (
                                    <div className="file-shower">
                                      <FilePdfOutlined className="file-preview" />
                                      <div className="close-icon">
                                        <Button
                                          icon={<CloseCircleOutlined />}
                                          type="primary"
                                          shape="rounded"
                                          danger
                                          disabled={false}
                                          onClick={() => {
                                            setFileList([]);
                                            form.setFieldsValue({
                                              judgement: {
                                                [name]: {
                                                  scrapePdfUrl: undefined,
                                                },
                                              },
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <Upload
                                      showUploadList={false}
                                      beforeUpload={beforeUploadDocFile}
                                      accept=".pdf"
                                      onChange={(e) =>
                                        handleFileChange(e, name)
                                      }
                                    >
                                      <Button icon={<UploadOutlined />}>
                                        Upload Pdf
                                      </Button>
                                    </Upload>
                                  )}
                                </Form.Item>
                              )
                            }
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  ))}
                  <div className="d-flex align-center justify-center">
                    <Button
                      type="dashed"
                      className="mb-16"
                      onClick={() => add(defaultFormValue)}
                      disabled={fields?.length === 10}
                    >
                      + Add Judgement
                    </Button>
                  </div>
                </>
              )}
            </Form.List>

            <Form.Item>
              <Space size={8}>
                <Button onClick={() => navigate(-1)}>Cancel</Button>
                <Button
                  type="primary"
                  disabled={!isFieldsChanged || !isPDFUrlValidate}
                  htmlType="submit"
                  loading={isLoading}
                >
                  Save
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </>
  );
};

export default ImportJudgement;
