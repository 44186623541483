import { Form, Input, Modal } from 'antd';
import React, { useState } from 'react';
import { mongoClient } from '../../../apollo';
import { CATEGORIES_KEYS, LIMIT } from '../../../common/constants';
import MongoCommonSelect from '../../../components/MongoCommonSelect';
import { MERGE_DATA } from '../../judges/graphql/mutations';
import GET_LAWYERS from '../graphql/queries';

const MergeDataModal = ({ open, onCancel, fetchData, category }) => {
  const variable = {
    filter: {
      skip: 0,
      limit: LIMIT,
      search: '',
      category: CATEGORIES_KEYS[category],
    },
    sort: { sortBy: 'DESC', sortOn: 'createdAt' },
  };
  const [form] = Form.useForm();
  const ids = Form?.useWatch('ids', form);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    const mergeIntoId = values?.ids?.[0];
    const dataToPass = {
      ids: values?.ids?.filter((_data, i) => i !== 0),
      name: values?.name ?? null,
      mergeIntoId,
      type: 'LAWYER',
    };
    try {
      const data = await mongoClient?.mutate({
        mutation: MERGE_DATA,
        fetchPolicy: 'network-only',
        variables: { data: dataToPass },
      });
      if (data?.data?.mergeData?.message) {
        fetchData();
        setLoading(false);
        onCancel();
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <>
      <Modal
        open={open}
        onCancel={onCancel}
        title="Merge or Edit Counsels"
        okText="Save"
        onOk={form.submit}
        okButtonProps={{ loading }}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            name="ids"
            label="Lawyers"
            rules={[{ required: true, message: 'Please select Lawyer' }]}
          >
            <MongoCommonSelect
              getPopupContainer={(trigger) => trigger?.parentNode}
              placeholder="Select Counsels to Merge or Edit"
              className="role-select-in-calls"
              showSearch
              allowClear
              query={GET_LAWYERS}
              fetchPolicy="network-only"
              responsePath="data.lawyersAdmin.data"
              valuePath="_id"
              labelPath="name"
              variables={variable}
              mode="multiple"
              value={ids}
              onChange={(_value, record) => {
                form.setFieldValue(
                  'name',
                  record?.[record?.length - 1]?.label ?? '',
                );
              }}
            />
          </Form.Item>
          <Form.Item name="name" label="New Name">
            <Input placeholder="New Name" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default MergeDataModal;
