import {
  CloseCircleOutlined,
  FileTextOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Upload,
} from 'antd';
import React from 'react';
import {
  CATEGORY_OPTIONS,
  ROUTES,
  STANDARD_DATE_FORMAT,
  STATES_LIST,
} from '../../../common/constants';
import { beforeUploadDocFile } from '../../../common/utils';
import useRouter from '../../../hooks/useRouter';

const { RangePicker } = DatePicker;

const NotificationForm = ({
  form,
  onFinish,
  loading,
  file,
  handleFileChange,
  handleRemoveAttachDoc,
}) => {
  const { navigate } = useRouter();

  return (
    <>
      <Form
        form={form}
        name="notification"
        onFinish={onFinish}
        layout="vertical"
      >
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} xl={12}>
            <Form.Item
              name="title"
              label="Title"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please enter Title',
                },
              ]}
            >
              <Input placeholder="Enter Title" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} xl={12}>
            <Form.Item
              name="category"
              label="Category"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please select Category',
                },
              ]}
            >
              <Select
                options={CATEGORY_OPTIONS}
                placeholder="Select Category"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} xl={12}>
            <Form.Item
              name="region"
              label="Region"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please select Region',
                },
              ]}
            >
              <Select
                options={STATES_LIST}
                placeholder="Select Region"
                showSearch
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} xl={12}>
            <Form.Item
              name="issuePeriod"
              label="Issue Period"
              rules={[
                {
                  required: true,
                  message: 'Please select Issue Period',
                },
              ]}
            >
              <RangePicker format={STANDARD_DATE_FORMAT} allowClear={false} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} xl={12}>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please enter Description',
                },
              ]}
            >
              <Input.TextArea rows={4} placeholder="Enter Description" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="pdfUrl"
              label="Pdf Url"
              rules={[
                {
                  required: true,
                  message: 'Please upload PDF',
                },
              ]}
            >
              {!file?.docFileObject?.name && !file.pdfUrl ? (
                <Upload
                  showUploadList={false}
                  beforeUpload={beforeUploadDocFile}
                  onChange={handleFileChange}
                  accept=".pdf,.PDF"
                >
                  <Button icon={<UploadOutlined />}>Upload Pdf</Button>
                </Upload>
              ) : (
                <div className="file-shower">
                  <FileTextOutlined className="file-preview" />
                  <div className="close-icon">
                    <Button
                      icon={<CloseCircleOutlined />}
                      type="primary"
                      shape="rounded"
                      danger
                      disabled={false}
                      onClick={() => {
                        handleRemoveAttachDoc();
                        form.setFieldValue('pdfUrl', null);
                      }}
                    />
                  </div>
                </div>
              )}
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            xl={24}
            className="d-flex align-center gap-8"
          >
            <Button onClick={() => navigate(ROUTES.NOTIFICATIONS)}>
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={loading}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default NotificationForm;
