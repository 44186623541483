import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { mongoClient } from '../../../apollo';
import { CATEGORIES_KEYS } from '../../../common/constants';
import { CREATE_KEYWORD, UPDATE_KEYWORD } from '../graphql/mutations';

function AddKeywordsModal({
  formPopup,
  handleCloseModal,
  handleModalForm,
  initialFormData,
  category,
}) {
  const [buttonLoader, setButtonLoader] = useState(false);
  const [form] = Form?.useForm();

  const handleClose = () => {
    handleCloseModal?.();
    form?.resetFields();
  };

  useEffect(() => {
    if (formPopup?.id && formPopup?.id !== 'new')
      form.setFieldsValue(initialFormData);
  }, [initialFormData]);

  const handleForm = async (values) => {
    setButtonLoader(true);
    const prepareValue = {
      ...values,
      regex: values?.regex?.map((item) => item?.reg),
      category: CATEGORIES_KEYS[category],
    };
    try {
      if (formPopup.id === 'new') {
        await mongoClient?.mutate({
          mutation: CREATE_KEYWORD,
          fetchPolicy: 'network-only',
          variables: {
            data: prepareValue,
          },
        });
      } else {
        await mongoClient?.mutate({
          mutation: UPDATE_KEYWORD,
          fetchPolicy: 'network-only',
          variables: {
            data: prepareValue,
            where: {
              id: formPopup?.id,
            },
          },
        });
      }
      handleModalForm?.(values);
    } catch (error) {
      return;
    } finally {
      handleClose();
      setButtonLoader(false);
    }
  };

  return (
    <Modal
      destroyOnClose
      title={`${formPopup?.id === 'new' ? 'Add Keyword' : 'Update Keyword'}`}
      open={formPopup?.isOpen}
      onCancel={handleClose}
      footer={[
        <Button key="submit" onClick={handleClose}>
          Cancel
        </Button>,
        <Button
          disabled={formPopup?.id !== 'new'}
          key="back"
          type="primary"
          loading={buttonLoader}
          onClick={() => form?.submit()}
        >
          Save
        </Button>,
      ]}
    >
      <div>
        <Form
          form={form}
          onFinish={handleForm}
          disabled={formPopup?.id !== 'new'}
        >
          <Form.Item
            name="label"
            label="Keyword Name"
            rules={[
              {
                required: true,
                message: 'Please enter Keyword Name',
              },
              {
                whitespace: true,
                message: 'Invalid input',
              },
            ]}
          >
            <Input placeholder="Please enter Keyword Name" />
          </Form.Item>
          <Form.Item label="Regex">
            <Form.List name="regex" label="Regex">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      className="dynamic-cat-input"
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        key={key}
                        name={[name, 'reg']}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter Regex',
                          },
                          {
                            whitespace: true,
                            message: 'Invalid input',
                          },
                        ]}
                      >
                        <Input placeholder="Regex" />
                      </Form.Item>
                      {formPopup?.id === 'new' && (
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      )}
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Regex
                    </Button>
                  </Form.Item>
                  <Form.ErrorList className="category-error" errors={errors} />
                </>
              )}
            </Form.List>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}

export default AddKeywordsModal;
