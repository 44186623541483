import { Button, Col, Modal, Row, Tag } from 'antd';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import React from 'react';
import {
  TRANSACTION_STATUS_COLOR,
  TRANSACTION_STATUS_LABELS,
  transactionDate,
} from '../../../common/constants';

export default function TransactionDetails({
  setShowModal,
  showModal,
  orderDetails,
  getTransactionType,
}) {
  return (
    <>
      <Modal
        title="Order Details"
        centered
        closable
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() =>
              orderDetails?.invoiceUrl &&
              // eslint-disable-next-line no-undef
              window.open(orderDetails.invoiceUrl)
            }
            disabled={!orderDetails?.invoiceUrl}
          >
            Download Invoice
          </Button>,
        ]}
      >
        <Row gutter={[16, 16]} className="mt-24">
          <Col span={12}>Ordered By: </Col>
          <Col span={12}>
            {orderDetails?.user
              ? `${orderDetails?.user?.firstName} ${orderDetails?.user?.lastName}`
              : `${orderDetails?.billing?.firstName} ${orderDetails?.billing?.lastName}`}
          </Col>
          <Col span={12}>Type:</Col>
          <Col span={12}>{capitalize(orderDetails?.type)}</Col>
          <Col span={12}>Item Name:</Col>
          <Col span={12}>
            {orderDetails?.[getTransactionType(orderDetails?.type)]?.title}
          </Col>
          <Col span={12}>Status:</Col>
          <Col span={12}>
            <Tag color={TRANSACTION_STATUS_COLOR?.[orderDetails?.status]}>
              {TRANSACTION_STATUS_LABELS?.[orderDetails?.status]}
            </Tag>
          </Col>
          <Col span={12}>Price:</Col>
          <Col span={12}>₹ {orderDetails?.amount?.toFixed(2)}</Col>
          <Col span={12}>Order Placed On:</Col>
          <Col span={12}>
            {dayjs(orderDetails?.createdAt)?.format(transactionDate)}
          </Col>
        </Row>
      </Modal>
    </>
  );
}
