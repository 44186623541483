/* eslint-disable no-underscore-dangle */
import {
  Card,
  Col,
  DatePicker,
  List,
  Row,
  Select,
  Spin,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { mongoClient } from '../../apollo';
import { AppContext } from '../../AppContext';
import {
  CATEGORY_OPTIONS,
  COURT_TYPES,
  OTHER_FILTER_DROPDOWN,
  STANDARD_DATE_FORMAT,
  USER_TYPE,
} from '../../common/constants';
import BenchListSelect from '../../components/BenchesListSelect';
import CommonSelect from '../../components/CommonSelect';
import MongoCommonSelect from '../../components/MongoCommonSelect';
import SearchComponent from '../../components/SearchComponent';
import { LIST_JUDGEMENTS } from '../judgements/graphql/queries';
import AppealDetails from './components/AppealDetails';
import PDFViewer from './components/PDFViewer';
import { LIST_ASSESSMENT_YEARS, LIST_CURRENT_BENCH } from './graphql/queries';
import './review-appeals.less';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const JUDGEMENT_LIMIT = 50;

function ReviewAppeals() {
  const containerRef = useRef(null);
  const { getCurrentUserRole } = useContext(AppContext);
  const role = getCurrentUserRole() || {};
  const [initLoading, setInitLoading] = useState(true);
  const [judgements, setJudgements] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedAppeal, setSelectedAppeal] = useState(null);
  const [assessmentYear, setAssessmentYear] = useState(null);
  const [formData, setFormData] = useState(null);
  const [loadingJudgementDetails, setLoadingJudgementDetails] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [selectedBench, setSelectedBench] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [searchAppeal, setSearchAppeal] = useState('');
  const [selectedOtherValue, setSelectedOtherValue] = useState(null);
  const [fullPayload, setFullPayload] = useState(null);
  const [updateJudgementAfterFilter, setUpdateJudgementAfterFilter] = useState(
    false,
  );
  const [category, setCategory] = useState(null);
  const [court, setCourt] = useState(null);
  const [pronounceDate, setPronounceDate] = useState([
    dayjs().subtract(1, 'month').startOf('day'),
    dayjs(),
  ]);

  const listJudgements = async ({ variables, isNewData = false }) => {
    await mongoClient
      ?.query({
        query: LIST_JUDGEMENTS,
        variables,
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setInitLoading(false);
        setDataLoading(false);
        setLoading(false);
        setUpdateJudgementAfterFilter(false);
        const newJudgementData = [
          ...judgements,
          ...res?.data?.judgmentsAdmin?.data,
        ];
        setJudgements(
          isNewData ? res?.data?.judgmentsAdmin?.data : newJudgementData,
        );
        setHasMore(newJudgementData?.length < res?.data?.judgmentsAdmin?.count);
      });
    setDataLoading(false);
  };

  useEffect(() => {
    if (updateJudgementAfterFilter) {
      setCurrentPage(1);
      return;
    }
    const payload = {
      skip: JUDGEMENT_LIMIT * (currentPage - 1),
      limit: JUDGEMENT_LIMIT,
      appellantSearch: searchAppeal,
      bench: selectedBench ? [selectedBench] : [],
    };
    if (category) {
      payload.category = category;
    }
    if (court) {
      payload.court = court;
    }
    if (assessmentYear) payload.assessmentYear = assessmentYear;

    if (pronounceDate) {
      payload.startDate = dayjs(pronounceDate?.[0])?.startOf('day');
      payload.endDate = dayjs(pronounceDate?.[1])?.endOf('day');
    }

    if (selectedOtherValue) {
      payload.withOutAssessmentYear =
        selectedOtherValue === 'without_assessment_year';
      payload.withOutPancard = selectedOtherValue === 'without_pancand';
      payload.isMiscellaneous = selectedOtherValue === 'misc';
    }
    setFullPayload(payload);

    listJudgements({
      variables: {
        filter: payload,
        sort: { sortBy: 'DESC', sortOn: 'createdAt' },
      },
    });
  }, [
    currentPage,
    assessmentYear,
    selectedBench,
    searchAppeal,
    selectedOtherValue,
    court,
    category,
    pronounceDate,
  ]);

  const fetchInitialList = () => {
    setJudgements([]);
    setLoading(true);
    setSelectedAppeal(null);
    setFormData(null);
    listJudgements({
      variables: {
        filter: fullPayload,
        sort: { sortBy: 'DESC', sortOn: 'createdAt' },
      },
      isNewData: true,
    });
  };

  const onLoadMore = () => {
    setLoading(true);
    setCurrentPage((prev) => prev + 1);
  };
  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef?.current;
      if (container) {
        const bottom =
          container.scrollHeight ===
          container.scrollTop + container.clientHeight;
        if (bottom && hasMore && !loading) {
          onLoadMore();
        }
      }
    };

    const container = containerRef?.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [hasMore, loading]);

  const resetReviewAppealList = () => {
    setHasMore(false);
    setJudgements([]);
    setSelectedAppeal(null);
    setFormData(null);
    setCurrentPage(1);
  };

  const handleStaffBench = (value) => {
    setDataLoading(true);
    resetReviewAppealList();
    setSelectedBench(value);
  };

  const handleAdminBench = (selectedValue) => {
    setDataLoading(true);
    resetReviewAppealList();
    setSelectedBench(selectedValue);
  };

  const handleSearchChange = debounce((value) => {
    setDataLoading(true);
    resetReviewAppealList();
    setSearchAppeal(value);
  }, 1000);

  const handleChange = (e) => {
    setDataLoading(true);
    resetReviewAppealList();
    if (selectedOtherValue === 'without_assessment_year')
      setSelectedOtherValue(null);
    setAssessmentYear(e);
  };

  const handleChangePronounceDate = (date) => {
    setDataLoading(true);
    resetReviewAppealList();
    setPronounceDate(date);
  };

  const handleOtherChanges = (value) => {
    setDataLoading(true);
    resetReviewAppealList();
    if (value === 'without_assessment_year') setAssessmentYear(null);
    setSelectedOtherValue(value);
  };

  useEffect(() => {
    setCurrentPage(1);
    if (updateJudgementAfterFilter) {
      const payload = {
        ...fullPayload,
        skip: 0,
      };
      listJudgements({
        variables: {
          filter: payload,
          sort: { sortBy: 'DESC', sortOn: 'createdAt' },
        },
      });
      setFullPayload(payload);
    }
  }, [updateJudgementAfterFilter]);

  const handleUpdateJudgementForm = () => {
    if (
      selectedOtherValue === 'without_assessment_year' ||
      selectedOtherValue === 'without_pancand'
    ) {
      setDataLoading(true);
      setJudgements([]);
      setSelectedAppeal(null);
      setFormData(null);
      setCurrentPage(0);
      setUpdateJudgementAfterFilter(true);
    }
  };

  const handleCourtChange = (value) => {
    setDataLoading(true);
    resetReviewAppealList();
    let finalValue = null;
    if (value) {
      finalValue = value;
    }
    setCourt(finalValue);
  };

  const handleCategoryChange = (value) => {
    setDataLoading(true);
    resetReviewAppealList();
    let finalValue = null;
    if (value) {
      finalValue = value;
    }
    setCategory(finalValue);
  };

  return (
    <>
      <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
        Review Appeals
      </Title>
      <div className="justify-start mb-12 d-flex flex-wrap gap-12">
        <div className="d-flex gap-12">
          <div className="d-flex gap-12 align-center review-appeals-filters">
            <MongoCommonSelect
              getPopupContainer={(trigger) => trigger?.parentNode}
              placeholder="Select Assessment Year"
              className="role-select-in-calls ml-8"
              showSearch
              allowClear
              query={LIST_ASSESSMENT_YEARS}
              fetchPolicy="network-only"
              responsePath="data.assessmentYearList"
              valuePath="assessmentYear"
              labelPath="assessmentYear"
              onChange={(e) => handleChange(e)}
              variables={{}}
              value={assessmentYear}
            />
          </div>
        </div>
        <div className="d-flex gap-12 align-center review-appeals-filters">
          <RangePicker
            allowClear
            className="role-select-in-calls ml-8"
            onChange={handleChangePronounceDate}
            format={STANDARD_DATE_FORMAT}
            value={pronounceDate}
          />
        </div>
        <div className="d-flex gap-12 align-center flex-wrap">
          <Select
            allowClear
            placeholder="Select Category"
            onChange={handleCategoryChange}
            value={category}
            className="role-select-in-calls ml-8"
          >
            {CATEGORY_OPTIONS?.map((e) => (
              <Select.Option value={e.value} key={e.value}>
                {e.label}
              </Select.Option>
            ))}
          </Select>
          {role === USER_TYPE.STAFF && (
            <CommonSelect
              getPopupContainer={(trigger) => trigger?.parentNode}
              placeholder="Select Benches"
              className="role-select-in-calls ml-8"
              showSearch
              allowClear
              query={LIST_CURRENT_BENCH}
              fetchPolicy="network-only"
              responsePath="currentUserBenches.data"
              valuePath="benchId"
              labelPath="benchSlug"
              onChange={(e) => handleStaffBench(e)}
              variables={{
                filter: {
                  limit: 10,
                  skip: 0,
                },
                sort: [
                  {
                    sortBy: 'ASC',
                    sortOn: 'createdAt',
                  },
                ],
              }}
              value={selectedBench}
              isReviewAppealStaff
            />
          )}
          <Select
            allowClear
            placeholder="Select Court"
            onChange={handleCourtChange}
            value={court}
            className="role-select-in-calls ml-8"
          >
            {COURT_TYPES?.map((e) => (
              <Select.Option value={e.value} key={e.value}>
                {e.label}
              </Select.Option>
            ))}
          </Select>
          {(role === USER_TYPE.SUPER_ADMIN || role === USER_TYPE.ADMIN) && (
            <BenchListSelect
              onChange={handleAdminBench}
              values={selectedBench}
            />
          )}
          <Select
            allowClear
            placeholder="Other"
            onChange={handleOtherChanges}
            value={selectedOtherValue}
            className="role-select-in-calls ml-8"
          >
            {OTHER_FILTER_DROPDOWN?.map((e) => (
              <Select.Option
                value={e.value}
                key={e.value}
                disabled={
                  (assessmentYear && e.value === 'without_assessment_year') ||
                  (assessmentYear && e.value === 'misc')
                }
              >
                {e.label}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
      <Row className="review-appeal-card-wrapper">
        <Col xs={24} sm={24} md={12} lg={7}>
          <Card className="full-height review-appeal-card">
            <div className="review-appeal-header">
              <span className="review-appeal-header-text">Appeal List</span>
            </div>
            <div className="review-appeal-search-bar">
              <SearchComponent
                className="list-search"
                getData={handleSearchChange}
                disabled={searchAppeal === '' && (initLoading || dataLoading)}
                placeholder="Search by keyword or citation number"
              />
            </div>
            <div className="review-appeal-content " ref={containerRef}>
              {initLoading || dataLoading ? (
                <div className="review-appeal-loader">
                  <Spin size="large" />
                </div>
              ) : (
                <>
                  <List
                    dataSource={judgements}
                    renderItem={(item) => (
                      <List.Item
                        className={`review-appeal-content-list-item ${
                          selectedAppeal === item?._id
                            ? 'review-appeal-content-list-item-selected'
                            : null
                        }`}
                        key={item?._id}
                        onClick={() => {
                          if (selectedAppeal === item?._id) return;
                          setSelectedAppeal(item?._id);
                          setLoadingJudgementDetails(true);
                        }}
                      >
                        {`${item?.appellant} V. ${item?.respondent}`}
                      </List.Item>
                    )}
                  />
                  {loading && (
                    <div className="review-appeal-content-list-spin">
                      <Spin />
                    </div>
                  )}
                </>
              )}
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <>
            <Card
              className={`full-height review-appeal-card ${
                loadingJudgementDetails ? null : 'review-appeal-card-loading'
              }`}
            >
              <div className="review-appeal-header">
                <span className="review-appeal-header-text">
                  Appeal Details
                </span>
              </div>
              <div className="review-appeal-content mt-8">
                <div className="review-appeal-loader">
                  <Spin size="large" />
                </div>
              </div>
            </Card>
          </>
          <>
            <Card
              className={`full-height review-appeal-card ${
                loadingJudgementDetails ? 'review-appeal-card-loading' : null
              }`}
            >
              <div className="review-appeal-header">
                <span className="review-appeal-header-text">
                  Appeal Details
                </span>
              </div>
              <div className="review-appeal-content mt-8">
                {selectedAppeal && (
                  <>
                    <AppealDetails
                      key={selectedAppeal}
                      id={selectedAppeal}
                      getFormData={(val) => {
                        setFormData(val);
                        setLoadingJudgementDetails(false);
                      }}
                      getJudgementLoadingValues={(val) =>
                        setLoadingJudgementDetails(val)
                      }
                      onUpdateJudgement={handleUpdateJudgementForm}
                      onAssesseeUpdate={handleUpdateJudgementForm}
                      fetchInitialList={fetchInitialList}
                    />
                  </>
                )}
              </div>
            </Card>
          </>
        </Col>
        <Col xs={24} sm={24} md={24} lg={9}>
          <Card className="full-height review-appeal-card-pdf">
            {/* eslint-disable-next-line no-nested-ternary */}
            {loadingJudgementDetails ? (
              <div className="review-appeal-loader-pdf">
                <Spin size="large" />
              </div>
            ) : (
              formData?.pdfUrl && (
                <PDFViewer fileUrl={formData?.pdfUrl ?? null} />
              )
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
}
export default ReviewAppeals;
