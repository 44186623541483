import { gql } from '@apollo/client';

const LIST_BENCH = gql`
  query BenchesAdmin($filter: BenchesFilter!, $sort: [BenchesSort]) {
    benchesAdmin(filter: $filter, sort: $sort) {
      count
      data {
        createdAt
        id
        key
        slug
        updatedAt
        value
        _id
      }
    }
  }
`;

export const LIST_ASSESSMENT_YEARS = gql`
  query AssessmentYearList {
    assessmentYearList {
      assessmentYear
    }
  }
`;

export const LIST_CURRENT_BENCH = gql`
  query CurrentUserBenches(
    $filter: UserBenchFilter!
    $sort: [CurrentUserBenchSort]
  ) {
    currentUserBenches(filter: $filter, sort: $sort) {
      count
      data {
        benchId
        benchKey
        benchSlug
        benchValue
        createdAt
        userId
        id
      }
    }
  }
`;
// eslint-disable-next-line import/prefer-default-export
export { LIST_BENCH };
