import { ExportOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Badge,
  Button,
  DatePicker,
  Divider,
  Modal,
  Radio,
  Select,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { capitalize, debounce, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  BOOKED_FROM_SELECTION,
  LIMIT,
  PAGE_SIZE_OPTIONS,
  ROLE_KEYS,
  ROUTES,
  SORT_OPTION,
  TEMPLATE_STATUS_COLOR,
  TRANSACTION_LOGS_FILTER,
  TRANSACTION_STATUS_COLOR,
  TRANSACTION_STATUS_LABELS,
  TRANSACTION_STATUS_SELECTION,
  TRANSACTION_TYPE,
  USER_TYPE_LABEL,
} from '../../common/constants';
import CommonSelect from '../../components/CommonSelect';
import SearchComponent from '../../components/SearchComponent';
import useRouter from '../../hooks/useRouter';
import { EXPORT_REPORTS } from '../reports/graphql/queries';
import { LIST_USER_QUERY } from '../users/graphql/queries';
import TransactionDetails from './components/TransactionDetails';
import { TRANSACTION_PAYOUT } from './graphql/mutations';
import { GET_CONSULTANT_AMOUNT, GET_TRANSACTION_LOGS } from './graphql/queries';

const { Title } = Typography;
const { RangePicker } = DatePicker;

function TransactionLogsList() {
  const { navigate, location: { search: searchParams } = {} } = useRouter();
  const params = new URLSearchParams(searchParams);
  const provider = params.get('providerId');
  const providerName = params.get('providerName');
  const [transactionLogsData, setTransactionLogsData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [orderDetails, setOrderDetails] = useState();
  const [filter, setFilter] = useState({
    limit: LIMIT,
    skip: 1,
    bookedFrom: 'WEB',
  });
  const [sort, setSort] = useState({ sortBy: 'DESC', sortOn: 'createdAt' });
  const [showAmount, setShowAmount] = useState({
    pendingAmount: null,
    TotalAmount: null,
  });
  const [modal, contextHolder] = Modal.useModal();

  const [listTransaction, { data: transactionData, loading }] = useLazyQuery(
    GET_TRANSACTION_LOGS,
    {
      fetchPolicy: 'network-only',
      onError() {},
      onCompleted: (res) => {
        setTransactionLogsData(res?.transactionLogsAdmin?.data);
      },
    },
  );

  const [transactionPayoutCall, { loading: payOutLoading }] = useMutation(
    TRANSACTION_PAYOUT,
    {
      fetchPolicy: 'network-only',
      onError() {},
      onCompleted: () => {
        setShowAmount({ pendingAmount: null, TotalAmount: null });
        const {
          skip,
          limit,
          status,
          type,
          startDate,
          endDate,
          search,
          providerId,
          bookedFrom,
        } = filter;
        listTransaction({
          variables: {
            filter: {
              skip: (skip - 1) * limit,
              limit,
              status,
              type,
              startDate,
              endDate,
              search,
              providerId,
              bookedFrom,
            },
            sort,
          },
        });
      },
    },
  );

  const [generateReport, { loading: preparingToExport }] = useLazyQuery(
    EXPORT_REPORTS,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [consultantAmount] = useLazyQuery(GET_CONSULTANT_AMOUNT, {
    fetchPolicy: 'network-only',
    onError() {},
    onCompleted: (res) => {
      const TotalAmount = res?.consultantAmount?.totalAmount
        ? `Rs.${res?.consultantAmount?.totalAmount?.toFixed(2)}`
        : 0;
      const pendingAmount = res?.consultantAmount?.pendingAmount
        ? `Rs.${res?.consultantAmount?.pendingAmount?.toFixed(2)}`
        : 0;
      setShowAmount({ TotalAmount, pendingAmount });
    },
  });

  useEffect(() => {
    const {
      skip,
      limit,
      status,
      type,
      startDate,
      endDate,
      search,
      providerId,
      bookedFrom,
    } = filter;
    listTransaction({
      variables: {
        filter: {
          skip: (skip - 1) * limit,
          limit,
          status,
          type,
          startDate,
          endDate,
          search,
          providerId,
          bookedFrom,
        },
        sort,
      },
    });
  }, [filter, sort]);

  useEffect(() => {
    if (filter?.startDate && filter?.endDate && filter?.providerId) {
      consultantAmount({
        variables: {
          filter: {
            startDate: filter?.startDate,
            endDate: filter?.endDate,
            where: {
              id: filter?.providerId,
            },
          },
        },
      });
    } else {
      setShowAmount({ pendingAmount: null, TotalAmount: null });
    }
  }, [filter]);

  const handleTableChange = (pagination, filters, sorter) => {
    setFilter({
      ...filter,
      status: filters?.status?.[0],
      type: filters?.type?.[0],
      skip: pagination?.current,
      limit: pagination?.pageSize,
    });
    setSort({
      sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
      sortOn: sorter?.field || sort?.sortOn,
    });
  };

  const handleSearchChange = debounce((value) => {
    setFilter({ ...filter, skip: 1, search: value });
  }, 2000);

  const handleDatePicker = (date) => {
    setFilter({
      ...filter,
      skip: 1,
      startDate: date && dayjs(date?.[0])?.startOf('day'),
      endDate: date && dayjs(date?.[1])?.endOf('day'),
    });
  };

  const getStatusFilterProps = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {TRANSACTION_STATUS_SELECTION?.map((option) => (
          <div key={option?.value}>
            <Radio
              checked={selectedKeys.includes(option?.value)}
              onChange={(e) => {
                const updatedKeys = e.target.checked ? [option?.value] : [];
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.label}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            disabled={!selectedKeys.length > 0}
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            Apply
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
    onFilter: (value, record) => record?.status?.includes(value),
  });

  const getTypeFilterProps = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {TRANSACTION_LOGS_FILTER?.map((option) => (
          <div key={option?.value}>
            <Radio
              checked={selectedKeys.includes(option?.value)}
              onChange={(e) => {
                const updatedKeys = e.target.checked ? [option?.value] : [];
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.label}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            disabled={!selectedKeys.length > 0}
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            Apply
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
    onFilter: (value, record) => record?.type?.includes(value),
  });

  const handleUser = (value) => {
    if (!value || provider) {
      navigate(ROUTES.TRANSACTION_LOGS);
    }
    setFilter({ ...filter, providerId: value });
  };

  const getTransactionType = (type) => {
    if (type === TRANSACTION_TYPE.TEMPLATE) {
      return 'template';
    }
    if (type === TRANSACTION_TYPE.SUBSCRIPTION) {
      return 'subscriptionPlan';
    }
    if (type === TRANSACTION_TYPE.CALL) {
      return 'consultationCall';
    }
  };

  const handleExportLogs = () => {
    const { providerId } = filter;
    generateReport({
      variables: {
        data: {
          startTime: filter?.startDate,
          endTime: filter?.endDate,
        },
        where: {
          consultantId: providerId,
        },
      },
    });
  };

  const columns = [
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      align: 'center',
      sorter: true,
      width: '10%',
      render: (value) => (
        <span>{value ? dayjs(value)?.format('Do MMM, YYYY') : '-'}</span>
      ),
    },
    {
      title: 'Purchaser',
      key: 'user',
      render: (value, record) => (
        <span>
          {record?.user
            ? `${record?.user?.firstName} ${record?.user?.lastName}`
            : `${record?.billing?.firstName} ${record?.billing?.lastName}`}
        </span>
      ),
    },
    {
      title: ' Purchaser Phone Number',
      key: 'user',
      render: (value, record) => (
        <span>
          {record?.user ? record?.user?.phoneNumber : record?.billing?.phone}
        </span>
      ),
    },
    {
      title: 'Provider(Role)',
      key: 'user',
      width: '10%',
      render: (value, record) => (
        <span>
          {record?.provider
            ? `${record?.provider?.firstName} ${record?.provider?.lastName} (${
                USER_TYPE_LABEL[record?.provider?.roles?.[0]]
              })`
            : '-'}
        </span>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      align: 'center',
      render: (value) => <span>{capitalize(value)}</span>,
      ...getTypeFilterProps(),
    },
    {
      title: 'Details',
      dataIndex: 'details',
      width: '10%',
      render: (value, record) => (
        <span>{record?.[getTransactionType(record?.type)]?.title}</span>
      ),
    },
    {
      title: 'Settled Date',
      dataIndex: 'settledDate',
      align: 'center',
      width: '10%',
      render: (value) => (
        <span>{value ? dayjs(value)?.format('Do MMM, YYYY') : '-'}</span>
      ),
    },
    {
      title: 'Price',
      dataIndex: 'amount',
      align: 'right',
      width: '10%',
      render: (price) => `₹ ${price.toFixed(2)}`,
    },
    {
      title: 'Paid',
      render: (item) => (
        <>
          {item.ledgers?.length ? (
            <>
              {item?.paidOutAt ? (
                <Badge count="Paid" color={TEMPLATE_STATUS_COLOR.PUBLISHED} />
              ) : (
                <Badge count="Unpaid" color={TEMPLATE_STATUS_COLOR.DRAFT} />
              )}
            </>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'Payment Mode',
      dataIndex: 'paymentMode',
      align: 'center',
      width: '12%',
      render: (mode) => (
        <Tag color={mode === 'OFFLINE' ? 'orange' : 'green'}>
          {capitalize(mode)}
        </Tag>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      render: (status) => (
        <Tag color={TRANSACTION_STATUS_COLOR?.[status]}>
          {TRANSACTION_STATUS_LABELS?.[status]}
        </Tag>
      ),
      ...getStatusFilterProps(),
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      align: 'center',
      fixed: 'right',
      width: 120,
      render: (record) => (
        <>
          <Button
            type="link"
            onClick={(e) => {
              e.stopPropagation();
              navigate(
                `${ROUTES.EDIT_TRANSACTION}/${record?.id}/${record?.user?.id}`,
              );
            }}
          >
            Edit
          </Button>
          <Divider type="vertical" />
          <Button
            type="link"
            onClick={(e) => {
              e?.stopPropagation();
              setShowModal(true);
              setOrderDetails(record);
            }}
          >
            View
          </Button>
        </>
      ),
    },
  ];

  const handleBookedFrom = (value) => {
    setFilter({
      ...filter,
      bookedFrom: value,
      limit: LIMIT,
      skip: 1,
    });
  };

  useEffect(() => {
    setFilter((prev) => ({ ...prev, providerId: provider }));
  }, [provider]);

  const handleMarkAsPaid = async () => {
    const popup = await modal.confirm({
      title: 'Are you sure you want to make transactions as paid?',
      content: '',
    });
    if (popup) {
      transactionPayoutCall({
        variables: {
          filter: {
            where: {
              id: filter?.providerId,
            },
            startDate: filter?.startDate,
            endDate: filter?.endDate,
          },
        },
      });
    }
  };

  return (
    <>
      <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
        Transaction Logs
        {transactionData?.transactionLogsAdmin?.count
          ? ` (${transactionData.transactionLogsAdmin.count})`
          : ''}
      </Title>
      <div className="d-flex gap-12 filter-input mb-12">
        <SearchComponent className="list-search" getData={handleSearchChange} />
        <div className="filter-input transaction-filters">
          <RangePicker allowClear onChange={handleDatePicker} />
          <div className="ml-8">
            <CommonSelect
              value={filter?.providerId}
              placeholder="Select Provider"
              className="full-width provider-select-filter"
              showSearch
              allowClear
              onChange={handleUser}
              query={LIST_USER_QUERY}
              fetchPolicy="network-only"
              responsePath="listUsers.user"
              valuePath="id"
              labelPath="firstName"
              optionalLabelPath="lastName"
              variables={{
                filter: {
                  roles: [
                    ROLE_KEYS.SUPER_ADMIN,
                    ROLE_KEYS.ADMIN,
                    ROLE_KEYS.STAFF,
                    ROLE_KEYS.PREMIUM_CONSULTANT,
                  ],
                },
                sort: [
                  {
                    sortOn: 'createdAt',
                    sortBy: SORT_OPTION.DESC,
                  },
                ],
              }}
              provider={
                provider
                  ? {
                      id: provider,
                      firstName: providerName,
                    }
                  : null
              }
            />
          </div>
          <Select
            placeholder="Transactions From"
            onChange={handleBookedFrom}
            defaultValue="WEB"
            className="role-select-in-calls ml-8"
            options={map(BOOKED_FROM_SELECTION)}
          />
          <Button
            icon={<PlusCircleOutlined />}
            className="ml-8"
            type="primary"
            onClick={() => {
              navigate(ROUTES?.ADD_TRANSACTION);
            }}
          >
            Add Transaction
          </Button>
          <Tooltip
            title={
              // eslint-disable-next-line no-nested-ternary
              !filter?.startDate
                ? 'Please select Start date and End date'
                : // eslint-disable-next-line no-nested-ternary
                !filter?.providerId
                ? 'Please select provider'
                : filter?.bookedFrom === BOOKED_FROM_SELECTION.MICROSITE.value
                ? 'Report generation on Microsite not available'
                : ''
            }
          >
            <Button
              icon={<ExportOutlined />}
              className="ml-8"
              type="primary"
              onClick={handleExportLogs}
              loading={preparingToExport}
              disabled={
                (!filter?.startDate && !filter?.endDate) ||
                !filter?.providerId ||
                filter?.bookedFrom === BOOKED_FROM_SELECTION.MICROSITE.value
              }
            >
              Generate Report
            </Button>
          </Tooltip>
          {showAmount?.TotalAmount ? (
            <Tooltip title="Total amount of filtered transactions">
              <Button loading={payOutLoading} disabled className="ml-8">
                {showAmount?.TotalAmount}
              </Button>
            </Tooltip>
          ) : (
            ''
          )}
          {showAmount?.pendingAmount ? (
            <Tooltip title="Pending amount of filtered transactions">
              <Button
                loading={payOutLoading}
                disabled={payOutLoading}
                onClick={handleMarkAsPaid}
                className="ml-8"
              >
                {showAmount?.pendingAmount}
              </Button>
            </Tooltip>
          ) : (
            ''
          )}
        </div>
      </div>
      {contextHolder}
      <Table
        columns={columns}
        rowKey={(record) => record?.id}
        dataSource={transactionLogsData}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 1300, y: `calc(100vh - 342px)` }}
        onRow={(record) => ({
          onClick: () => {
            navigate(
              `${ROUTES.EDIT_TRANSACTION}/${record?.id}/${record?.user?.id}`,
            );
          },
        })}
        pagination={{
          total: transactionData?.transactionLogsAdmin?.count,
          current: filter.skip,
          pageSize: filter.limit,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
        expandable={{
          expandedRowRender: (record) => (
            <Table
              size="small"
              columns={[
                {
                  title: 'Title',
                  dataIndex: 'label',
                  render: (label) => capitalize(label),
                },
                {
                  title: 'Amount',
                  dataIndex: 'amount',
                  align: 'right',
                  render: (price) => `₹ ${price?.toFixed(2)}`,
                },
                {
                  title: 'Percentage',
                  dataIndex: 'percentage',
                  align: 'center',
                  render: (percentage) => `${percentage}%`,
                },
              ]}
              rowKey={record?.id}
              dataSource={record?.ledgers}
              pagination={false}
            />
          ),
          expandRowByClick: true,
          rowExpandable: (record) => record.ledgers?.length > 0,
        }}
      />
      <TransactionDetails
        setShowModal={setShowModal}
        showModal={showModal}
        orderDetails={orderDetails}
        getTransactionType={getTransactionType}
      />
    </>
  );
}

export default TransactionLogsList;
